html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: MinecraftRegular;
  src: local("MinecraftRegular"),
    url(./assets/MinecraftRegular.otf) format("opentype");
}

.mc {
  padding: 1em;
  margin: 1em;
  margin-left: 0;
  font-family: MinecraftRegular;
  background-color: #362400;
  border-radius: 10px;
}

.mc_black {
  color: #000;
}

.mc_dark_blue {
  color: #00a;
}

.mc_dark_green {
  color: #0a0;
}

.mc_dark_aqua {
  color: #0aa;
}

.mc_dark_red {
  color: #a00;
}

.mc_dark_purple {
  color: #a0a;
}

.mc_gold {
  color: #fa0;
}

.mc_gray {
  color: #aaa;
}

.mc_dark_gray {
  color: #555;
}

.mc_blue {
  color: #55f;
}

.mc_green {
  color: #5f5;
}

.mc_aqua {
  color: #5ff;
}

.mc_red {
  color: #f55;
}

.mc_light_purple {
  color: #f5f;
}

.mc_yellow {
  color: #ff5;
}

.mc_white {
  color: #fff;
}

.mc_bold {
  font-weight: 700;
}

.mc_italic {
  font-style: italic;
}

.mc_strikethrough,
.mc_underlined {
  position: relative;
  display: inline-block;
}

.mc_strikethrough::before,
.mc_underlined::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
}

.mc_strikethrough::before {
  border-bottom: 2px solid;
  border-bottom-color: inherit;
  top: 55%;
}

.mc_underlined::after {
  border-bottom: 2px solid;
  border-bottom-color: inherit;
  bottom: -8%;
}
